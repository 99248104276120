<template>
  <b-row>
    <section id="home" class="bg-gray-light">
      <b-row align-v="end" class="bg-home-left">
        <b-col>
          <b-container class="align-center">
            <b-row>
              <b-col cols="12" md="6" lg="5">
                <b-row class="text-padding">
                  <b-row class="texts-home">
                    <b-img-lazy
                      src="./img/LOGO_SETE_OFFICE.svg"
                      fluid
                      class="logo animate__animated animate__bounce"
                    />
                    <p
                      class="title-home color-dar-blue animate__animated animate__fadeInLeft"
                    >
                      Seu portal para <br />o conhecimento
                    </p>
                    <p
                      class="subtitle-home color-orange animate__animated animate__fadeInRight"
                    >
                      Acesse um universo de aprendizado e cultura com a Sete
                      Office.
                    </p>
                  </b-row>
                </b-row>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="7"
                class="img-section"
                align-self="end"
              >
                <b-img-lazy
                  src="./img/FOTO_JOVENS_HOME.png"
                  class="img-header animate__animated animate__fadeIn"
                />
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
      <nav>
        <b-container class="align-center">
          <b-row align-h="center">
            <b-col
              class="bg-gray menu-item"
              cols="4"
              md="3"
              @click="scrollToSection('about')"
            >
              sobre
            </b-col>
            <b-col
              class="bg-orange menu-item"
              cols="4"
              md="3"
              @click="scrollToSection('solutions')"
            >
              soluções
            </b-col>
            <b-col
              class="bg-brown menu-item"
              cols="4"
              md="3"
              @click="scrollToSection('products')"
            >
              produtos
            </b-col>
          </b-row>
        </b-container>
      </nav>
    </section>
    <divider-page />
    <section id="about">
      <b-container class="align-center">
        <b-row align-v="center">
          <b-col cols="12" md="6" lg="5">
            <b-img-lazy src="./img/JOVEM_CAMISA_BRANCA_ORNAMENTOS.png" fluid />
          </b-col>
          <b-col cols="12" md="6" lg="7" class="padding-section text-padding">
            <b-row>
              <h2
                class="color-orange animate__animated animate__fadeIn animate__delay-1s"
              >
                O que fazemos?
              </h2>
            </b-row>
            <b-row>
              <p class="animate__animated animate__fadeIn animate__delay-2s">
                Na Sete Office, acreditamos que a educação é a chave para
                transformar o mundo. Por isso, nos dedicamos a desenvolver
                soluções inovadoras que facilitem o acesso ao conhecimento e à
                cultura.
              </p>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section id="solutions" class="bg-dark-blue">
      <b-row align-v="center">
        <b-col cols="12" md="6" lg="4" class="padding-section">
          <b-row>
            <h2
              class="color-orange create-solutions animate__animated animate__fadeIn animate__delay-2s"
            >
              Desenvolver<br />
              Soluções para<br />
              Facilitar a <br />
              Educação<br />
            </h2>
          </b-row>
        </b-col>
        <b-col cols="12" md="6" lg="2" align-self="end">
          <b-img-lazy src="./img/FOTO_JOVEM_CAMISA_LARANJA.png" fluid />
        </b-col>
        <b-col cols="12" md="12" lg="3" class="padding-section">
          <b-row
            class="color-white text-solutions text-padding animate__animated animate__fadeIn animate__delay-2s"
          >
            Nossa missão é clara: democratizar a leitura e a aprendizagem,
            oferecendo recursos que atendam às necessidades de um mundo em
            constante evolução. Com uma vasta coleção de títulos e um
            compromisso inabalável com a excelência, a Sete Office é sua
            parceira na jornada educacional.</b-row
          >
        </b-col>
      </b-row>
    </section>
    <divider-page />
    <section id="childs" class="padding-section">
      <b-container class="align-center">
        <carousel
          :per-page-custom="[
            [100, 1],
            [600, 2],
            [1025, 3],
          ]"
          :autoplay="true"
          :autoplayTimeout="3000"
          :loop="true"
          :mouse-drag="true"
        >
          <slide v-for="(child, index) in childs" :key="index">
            <b-row>
              <b-col cols="12">
                <b-img-lazy :src="child.img" fluid v-bind="lazy" />
              </b-col>
              <b-col cols="12">
                <h2
                  class="animate__animated animate__fadeIn animate__delay-2s"
                  :class="child.classTitle"
                >
                  {{ child.title }}
                </h2>
              </b-col>
              <b-col cols="12">
                <p class="animate__animated animate__fadeIn animate__delay-2s">
                  {{ child.text }}
                </p>
              </b-col>
            </b-row>
          </slide>
        </carousel>
      </b-container>
    </section>
    <divider-page />
    <section id="mission">
      <b-container class="align-center">
        <b-row align-v="end">
          <b-col cols="12" md="12" lg="7" class="text-padding">
            <h3
              class="color-orange animate__animated animate__fadeIn animate__fadeIn animate__delay-2s"
            >
              Missão
            </h3>

            <p
              class="animate__animated animate__fadeIn animate__fadeIn animate__delay-2s"
            >
              Nossa missão é promover o conhecimento e a cultura através da
              publicação e distribuição de livros de alta qualidade,
              proporcionando acesso à leitura para pessoas de todas as idades e
              interesses.
            </p>

            <h3
              class="color-dark-gray animate__animated animate__fadeIn animate__delay-2s"
            >
              Visão
            </h3>

            <p class="animate__animated animate__fadeIn animate__delay-2s">
              Ser reconhecida como a principal editora e distribuidora de livros
              no mercado nacional, destacando-se pela inovação, diversidade de
              títulos e excelência no atendimento aos nossos clientes.
            </p>

            <h3
              class="color-dark-brown animate__animated animate__fadeIn animate__delay-2s"
            >
              Valores
            </h3>

            <p class="animate__animated animate__fadeIn animate__delay-2s">
              Compromisso com a qualidade Garantir a publicação de obras bem
              revisadas e de alto valor cultural e educativo. Acessibilidade
              Facilitar o acesso à leitura para todas as camadas da sociedade.
              <br />
              Diversidade Promover uma ampla variedade de gêneros e temas,
              respeitando e valorizando a pluralidade cultural.
              <br />
              Inovação Buscar constantemente novas tecnologias e métodos para
              melhorar nossos processos e produtos.
              <br />
              Sustentabilidade Operar de maneira responsável e sustentável,
              respeitando o meio ambiente e a sociedade. Desenvolver
            </p>
          </b-col>
          <b-col cols="12" md="12" lg="5">
            <b-img-lazy src="./img/FOTO_MENINA_BG.png" fluid />
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section id="education" class="bg-cian">
      <b-container class="align-center">
        <b-row align-v="end">
          <b-col cols="12" md="7" lg="7" class="text-padding">
            <h2
              class="color-orange animate__animated animate__fadeIn animate__delay-2s"
            >
              Desenvolver Soluções para Facilitar a Educação
            </h2>

            <p class="animate__animated animate__fadeIn animate__delay-2s">
              Na Sete Office, acreditamos que a educação é a chave para
              transformar o mundo. Por isso, nos dedicamos a desenvolver
              soluções inovadoras que facilitem o acesso ao conhecimento e à
              cultura. Nossos livros são cuidadosamente selecionados e
              distribuídos para garantir que cada leitor encontre exatamente o
              que precisa para crescer e aprender. <br /><br />

              Investimos em tecnologias avançadas e métodos eficientes para
              levar educação de qualidade a todos os cantos do país. Seja nas
              escolas, bibliotecas ou em casa, a Sete Office está presente,
              proporcionando materiais didáticos e literários que inspiram e
              capacitam. <br /><br />

              Nossa missão é clara: democratizar a leitura e a aprendizagem,
              oferecendo recursos que atendam às necessidades de um mundo em
              constante evolução. Com uma vasta coleção de títulos e um
              compromisso inabalável com a excelência, a Sete Office é sua
              parceira na jornada educacional.<br /><br />

              Junte-se a nós e descubra como nossas soluções podem transformar a
              educação e abrir portas para um futuro brilhante.
            </p>
          </b-col>
          <b-col cols="12" md="5" lg="5">
            <b-img-lazy src="./img/FOTO_ORNAMENTOS_BG.png" fluid />
          </b-col>
        </b-row>
      </b-container>
    </section>
    <divider-page />
    <section id="products" class="padding-section">
      <b-container class="align-center">
        <b-row class="collection text-padding">
          <b-img-lazy src="./img/LOGO_G10.svg" class="partner" />
        </b-row>
        <b-row class="text-padding">
          <b-row align-v="start">
            <b-img-lazy
              src="./img/LIVROS_G10_1.png"
              fluid
              class="cover-products"
            />
          </b-row>
          <h3 class="color-orange">Oficina de Música</h3>
          <p>
            A música está ligada ao ser humano desde muito cedo, sem ela o mundo
            se tornaria vazio e sem espírito. A música é uma arte que vem sendo
            esquecida, mas que deve ser retomada nas escolas, pois ela propicia
            ao aluno um aprendizado global e emotivo com o mundo. Na sala de
            aula, ela poderá auxiliar de forma significativa na aprendizagem.
          </p>
          <b-row align-v="start">
            <b-img-lazy
              src="./img/LIVROS_G10_2.png"
              fluid
              class="cover-products"
            />
          </b-row>
          <h3 class="color-orange">Educação para Mobilidade no Trânsito</h3>
          <p>
            Na BNCC – Base Nacional Comum Curricular está especificado que
            Educação para o Trânsito é um Tema Contemporâneo Transversal ao
            Currículo e deve ser trabalhado nas escolas com o objetivo de formar
            cidadãos mais conscientes e preparados para enfrentar a vida e o
            trânsito, contribuindo na construção de valores, como o respeito ao
            próximo e a proteção da vida – nosso bem maior. A Coleção Educação
            para Mobilidade – Trânsito, composta de livros para o Ensino
            Fundamental do 1º ao 9º anos, auxiliará os estudantes a
            compreenderem os elementos e e as situações vivenciadas no transito.
          </p>
          <b-row align-v="start">
            <b-img-lazy
              src="./img/LIVROS_G10_3.png"
              fluid
              class="cover-products"
            />
          </b-row>
          <h3 class="color-orange">Nós Brasileiros</h3>
          <p>
            As leis 10.639 e 11.645 obrigam o ensino dos temas História e
            Cultura africana e indígena nas escolas, onde os conteúdos devem ser
            ministrados no âmbito de todo o currículo escolar, de forma
            transversalizada. principalmente nos componentes curriculares de
            Arte, História e Literatura brasileiras. <br /><br />Para apoiar,
            implementar e ampliar as aprendizagens sobre a temática, a coleção
            de livros “Nós, Brasileiros” reflete em grande medida a preocupação
            de democratização das relações sociais no Brasil e vai de encontro
            ao que determina a legislação educacional, sendo essa coleção um
            conjunto de livros uteis para que crianças e adolescentes conheçam a
            sociedade brasileira e que a partir desse conhecimento, estereótipos
            sejam desconstruídos para avançarmos na construção de uma sociedade
            menos preconceituosa e que respeita a diversidade.
          </p>
          <b-row align-v="start">
            <b-img-lazy
              src="./img/LIVROS_G10_4.png"
              fluid
              class="cover-products"
            />
          </b-row>
          <h3 class="color-orange">Projeto Combate ao Bullying</h3>
          <p>
            A convivencia entre pessoas e principalmente entre crianças e
            adolescentes muitas vezes geram conflitos e provocações criando
            ambiente propicio ao bullyng. Previna sua escola de problemas de
            convivencia implantando o cobate ao bullyng adotando o kit de livros
            produzidos especialmente para orientar pais, professores e alunos a
            desenvolver uma convivência harmoniosa e prazerosa. O kit de combate
            ao bullying atende alunos dos anos finais do ensino fundamental
            trazendo a docentes, alunos e famílias essa temática contemporânea
            que contribuirá de forma significativa para o desenvolvimento humano
            de todos.
          </p>
        </b-row>
        <b-row class="collection text-padding">
          <b-img-lazy src="./img/LOGO_LIBERTA.svg" class="partner" />
        </b-row>
        <b-row class="text-padding">
          <b-row align-v="start">
            <b-img-lazy
              src="./img/LIVROS_LIBERTA_1.png"
              fluid
              class="cover-products"
            />
          </b-row>
          <h3 class="color-orange">Educação financeira na prática</h3>
          <p>
            A coleção “Vamos Juntos: Educação Financeira na Prática!” é composta
            por 9 volumes, do 1º ao 9º ano do Ensino Fundamental. Cada livro
            apresenta 4 capítulos, e cada capítulo um eixo discutido na Educação
            Financeira, são eles: dinheiro, consumo e planejamento. Para cada
            livro, o texto discorrido foi referente a um “tema geral”
            relacionado à Educação Financeira, e para cada eixo foi destacado um
            subtema partindo desse “tema geral” do livro.
          </p>
          <b-row align-v="start">
            <b-img-lazy
              src="./img/LIVROS_LIBERTA_2.png"
              fluid
              class="cover-products"
            />
          </b-row>
          <h3 class="color-orange">Lendo, Voando e Aprendendo</h3>
          <p>
            Ler desde cedo é essencial pra tomar gosto pelos livros. E isso é
            ótimo porque as histórias ajudam no desenvolvimento mental e
            emocional das crianças! A literatura infantil e infanto-juvenil é,
            por essência, a porta de entrada ao mundo da leitura e da
            literatura, cuja compreensão consideramos a substância mais apurada
            do processo de leitura.vés da redação e interpretação de textos
          </p>
          <b-row align-v="start">
            <b-img-lazy
              src="./img/LIVROS_LIBERTA_3.png"
              fluid
              class="cover-products"
            />
          </b-row>
          <h3 class="color-orange">Avança Mais Brasil</h3>
          <p>
            Os livros da Coleção Avança Mais Brasil apresentam-se como
            ferramentas de suma importância no ensino e aprendizagem. São livros
            que se caracterizam por: qualidades gráficas; legibilidade;
            inteligibilidade; ilustrações; adequação do conteúdo aos interesses
            dos alunos e aos objetivos do ensino com exercícios interessantes.
            Assim, os livros da coleção apresentam os seguintes aspectos:<br /><br />
            a) Foram elaborados em conformidade com a Base Nacional Comum
            Curricular (BNCC); <br />b) Os conteúdos englobam atividades de
            acordo com as matrizes do SAEB (Sistema de Avaliação da Educação
            Básica); <br />c) Dispõem de exercícios, explicações, dicas e
            simulados, para o aluno treinar e desenvolver habilidades de
            leitura, escrita e desenvolvimento lógico-matemático. <br />d)
            Apresentam conteúdos modernos, trazendo desafios e atividades
            diferenciadas e motivadoras; <br /><br />O uso desse material nos
            anos iniciais e finais do Ensino Fundamental além de preparar o
            aluno para a avaliação do SAEB e consequentemente a melhoria do IDEB
            (Indice de Desenvolvimento da Educação Básica), abre possibilidades
            para que a escola e o educador façam um diagnóstico exclusivo de
            suas turmas, valorizando os acertos e apontando as lacunas
            existentes no processo de ensino.
          </p>
        </b-row>
        <b-row class="collection text-padding">
          <b-img-lazy src="./img/LOGO_DOGO.svg" class="partner" />
        </b-row>
        <b-row align-v="start">
          <b-col cols="12" md="4" lg="3" class="cover-padding">
            <b-img-lazy
              src="./img/LIVROS_DOGO_1.png"
              fluid
              class="cover-product"
            />
          </b-col>
          <b-col cols="12" md="8" class="text-padding">
            <h3 class="color-orange">
              ROBÓTICA EDUCACIONAL SUSTENTÁVEL COM PROGRAMAÇÃO VISUAL
            </h3>
            <p>
              O livro tem uma abordagem inovadora e sustentável para a
              introdução da robótica educacional no contexto escolar. Ele se
              concentra em fornecer aos educadores e alunos uma compreensão
              sólida dos conceitos fundamentais da robótica, enquanto promove a
              sustentabilidade e o uso responsável dos recursos.
            </p>
          </b-col>
        </b-row>
        <b-row align-v="start">
          <b-col cols="12" md="4" lg="3" class="cover-padding">
            <b-img-lazy
              src="./img/LIVROS_DOGO_2.png"
              fluid
              class="cover-product"
            />
          </b-col>
          <b-col cols="12" md="8" class="text-padding">
            <h3 class="color-orange">
              INICIAÇÃO AO DESENVOLVIMENTO DO PENSAMENTO COMPUTACIONAL COM
              PROGRAMAÇÃO VISUAL
            </h3>
            <p>
              Compreender o cenário atual da educação e sua relação com a
              tecnologia é fundamental para preparar os estudantes para os
              desafios do século 21. O livro surge como parte integrante desse
              movimento, oferecendo uma abordagem abrangente e prática para
              desenvolver o Pensamento Computacional.
            </p>
          </b-col>
        </b-row>
        <b-row class="collection text-padding">
          <b-img-lazy src="./img/LOGO_AME.svg" class="partner" />
        </b-row>
        <b-row align-v="start">
          <b-col cols="12" md="4" lg="3" class="cover-padding">
            <b-img-lazy
              src="./img/LIVROS_AME_1.png"
              fluid
              class="cover-product"
            />
          </b-col>
          <b-col cols="12" md="8" class="text-padding">
            <h3 class="color-orange">Global Citizen Teens</h3>
            <p>
              A coleção “Global Citizen Teens” foi cuidadosamente elaborada para
              atender às séries do Ensino Fundamental II (6° ao 9° anos) em
              contraturno ou ensino integral, pois se trata de um material
              complementar. Com o intuito de promover uma experiência de
              aprendizado mais acessível e efetiva, optamos por segmentar o
              nível A1 do Quadro Europeu Comum de Referência para Línguas em
              dois volumes distintos. Nosso material, não só incorpora uma
              metodologia pedagógica consagrada, mas também está plenamente
              alinhado às diretrizes estabelecidas pela Base Nacional Comum
              Curricular (BNCC), assegurando um ensino de qualidade e
              contemporâneo.
            </p>
          </b-col>
        </b-row>
        <b-row align-v="start">
          <b-col cols="12" md="4" lg="3" class="cover-padding">
            <b-img-lazy
              src="./img/LIVROS_AME_2.png"
              fluid
              class="cover-product"
            />
          </b-col>
          <b-col cols="12" md="8" class="text-padding">
            <h3 class="color-orange">Coleção Global Citizen</h3>
            <p>
              Apresentamos COLEÇÃO GLOBAL CITIZEN, um curso de inglês que vai
              além das expectativas dos estudantes, proporcionando uma
              experiência única e personalizada. A coleção foi criada de acordo
              com o Common European Framework of References for Languages –
              CEFR, em sua tradução literal, “Quadro Europeu Comum de Referência
              para Línguas”, um padrão internacional amplamente utilizado na
              Europa e em outros países do mundo para descrever o nível de
              proficiência e competência linguística em línguas europeias.
            </p>
          </b-col>
        </b-row>
        <b-row align-v="start">
          <b-col cols="12" md="4" lg="3" class="cover-padding">
            <b-img-lazy
              src="./img/LIVROS_AME_3.png"
              fluid
              class="cover-product"
            />
          </b-col>
          <b-col cols="12" md="8" class="text-padding">
            <h3 class="color-orange">Turismo Essential</h3>
            <p>
              Seja um profissional diferenciado no mercado. É preciso superar a
              barreira que dificulta a vinda de turistas estrangeiros para o
              Brasil: O idioma. TURISMO ESSENTIAL, um curso de inglês e espanhol
              voltado para os profissionais do Setor do Turismo.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <divider-page />
    <section id="newsletter" class="padding-section">
      <b-container class="align-center text-padding">
        <b-row>
          <h2 class="color-dark-blue">Receba novidades, inscreva-se!</h2>
        </b-row>
        <b-row>
          <p>
            Inscreva-se em nossa newsletter para receber promoções e conteúdos
            exclusivos.
          </p>
        </b-row>
        <b-form class="form" @submit.prevent.stop="sendForm" :disabled="isLoading">
          <b-row>
            <b-col cols="12" md="6" lg="4">
              <b-form-input
                placeholder="nome"
                type="text"
                class="bg-gray"
                v-model.trim="form.name"
                :disabled="isLoading"
              />
            </b-col>
            <b-col cols="12" md="6" lg="4">
              <b-form-input
                placeholder="email"
                type="email"
                class="bg-orange"
                v-model.trim="form.email"
                :disabled="isLoading"
              />
            </b-col>
            <b-col cols="12" md="12" lg="4">
              <b-button class="bg-brown" type="submit" :disabled="isLoading">{{
                isLoading ? "Enviando ..." : "Cadastrar"
              }}</b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
    </section>
    <footer class="bg-orange padding-section">
      <b-container class="align-center text-padding">
        <b-row align-v="center">
          <b-col
            cols="12"
            md="7"
            lg="8"
            class="color-white text-left padding-section"
          >
            <b-img-lazy
              src="./img/LOGO_SETE_OFFICE_BRANCA.svg"
              height="80rem"
            />
          </b-col>
          <b-col cols="12" md="5" lg="4" class="color-white text-left">
            <b-row md="12" class="padding-section">
              <b-col cols="3" md="3" lg="2">
                <a
                  class="whatsapp-btn"
                  href="https://api.whatsapp.com/send?phone=5598986261114&text=Olá gostaria de saber mais informações"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M12.031 6.172c-3.181 0-5.767 2.586-5.768 5.766-.001 1.298.38 2.27 1.019 3.287l-.582 2.128 2.182-.573c.978.58 1.911.928 3.145.929 3.178 0 5.767-2.587 5.768-5.766.001-3.187-2.575-5.77-5.764-5.771zm3.392 8.244c-.144.405-.837.774-1.17.824-.299.045-.677.063-1.092-.069-.252-.08-.575-.187-.988-.365-1.739-.751-2.874-2.502-2.961-2.617-.087-.116-.708-.94-.708-1.793s.448-1.273.607-1.446c.159-.173.346-.217.462-.217l.332.006c.106.005.249-.04.39.298.144.347.491 1.2.534 1.287.043.087.072.188.014.304-.058.116-.087.188-.173.289l-.26.304c-.087.086-.177.18-.076.354.101.174.449.741.964 1.201.662.591 1.221.774 1.394.86s.274.072.376-.043c.101-.116.433-.506.549-.68.116-.173.231-.145.39-.087s1.011.477 1.184.564.289.13.332.202c.045.072.045.419-.1.824zm-3.423-14.416c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm.029 18.88c-1.161 0-2.305-.292-3.318-.844l-3.677.964.984-3.595c-.607-1.052-.927-2.246-.926-3.468.001-3.825 3.113-6.937 6.937-6.937 1.856.001 3.598.723 4.907 2.034 1.31 1.311 2.031 3.054 2.03 4.908-.001 3.825-3.113 6.938-6.937 6.938z"
                    />
                  </svg>
                </a>
              </b-col>
              <b-col cols="9" md="9" lg="10">
                <small>TELEFONE</small>
                <a href="tel:+5598986261114">+55 (98) 98626.1114</a>
              </b-col>
            </b-row>

            <b-row md="12" class="padding-section">
              <b-col cols="3" md="3" lg="2">
                <a
                  class="whatsapp-btn"
                  href="mailto:seteofficeslz@gmail.com"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M12 .02c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.99 6.98l-6.99 5.666-6.991-5.666h13.981zm.01 10h-14v-8.505l7 5.673 7-5.672v8.504z"
                    />
                  </svg>
                </a>
              </b-col>
              <b-col cols="9" md="9" lg="10">
                <small>E-MAIL</small>
                <a href="mailto:seteofficeslz@gmail.com" class="email"
                  >seteofficeslz@gmail.com</a
                >
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </footer>
    <b-button
      id="jumpUpButton"
      class="jump-up"
      @click="scrollToSection('home')"
      title="Ir para o topo"
    />
  </b-row>
</template>

<script>
import DividerPage from "@/components/DividerPage.vue";
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "HomeView",
  components: { DividerPage, Carousel, Slide },
  data() {
    return {
      form: {
        name: null,
        email: null,
      },
      isLoading: false,
      lazy: {
        center: true,
        fluidGrow: true,
        blank: true,
        blankColor: "#bbb",
      },
      childs: [
        {
          img: "./img/FOTO_SCROLL_1.png",
          classTitle: "color-orange",
          title: "Educação contra as drogas nas escolas",
          text: "A “educação antidrogas” é um tema transversal e multidisciplinar o que implica que a abordagem dessa questão deve ser dada de forma integrada entre os componenetes curriculares através de projetos educativos relacionados a temática.",
        },
        {
          img: "./img/FOTO_SCROLL_2.png",
          classTitle: "color-gray",
          title: "Educação financeira para todos",
          text: "Cada livro apresenta 4 capítulos, e cada capítulo um eixo discutido na Educação Financeira, são eles: dinheiro, consumo e planejamento. Para cada livro, o texto discorrido foi referente a um “tema geral” relacionado à Educação Financeira.",
        },
        {
          img: "./img/FOTO_SCROLL_3.png",
          classTitle: "color-dark-brown",
          title: "Robótica educacional nas escolas",
          text: "Ensinar robótica educacional para alunos dos anos finais do ensino fundamental, além de ser uma maneira divertida e interessante de aprender, ajuda a desenvolver habilidades importantes em diversas áreas, como ciência, tecnologia, engenharia, matemática e eletrônica.",
        },
        {
          img: "./img/FOTO_SCROLL_4.png",
          classTitle: "color-dark-brown",
          title: "Preparar o aluno para a avaliação do SAEB",
          text: "O uso desse material nos anos iniciais e finais do Ensino Fundamental além de preparar o aluno para a avaliação do SAEB e consequentemente a melhoria do IDEB, abre possibilidades para que a escola e o educador façam um diagnóstico exclusivo de suas turmas.",
        },
        {
          img: "./img/FOTO_SCROLL_5.png",
          classTitle: "color-orange",
          title: "Cultivando Consciência Ecológica",
          text: "Ao integrar a educação ambiental ao currículo, ajudamos os alunos a compreenderem os desafios ambientais e a importância de suas ações para um futuro sustentável. Com a Sete Office, cada leitura se torna um passo em direção a um planeta mais verde.",
        },
        {
          img: "./img/FOTO_SCROLL_6.png",
          classTitle: "color-gray",
          title: "Combatendo o Bullying nas Escolas",
          text: "O kit de combate ao bullying atende alunos dos anos finais do ensino fundamental trazendo a docentes, alunos e famílias essa temática contemporânea que contribuirá de forma significativa para o desenvolvimento humano de todos.",
        },
        {
          img: "./img/FOTO_SCROLL_7.png",
          classTitle: "color-dark-brown",
          title: "Educação para mobilidade no trânsito",
          text: "Na BNCC – Base Nacional Comum Curricular está especificado que Educação para o Trânsito é um Tema Contemporâneo Transversal ao Currículo e deve ser trabalhado nas escolas com o objetivo de formar cidadãos mais conscientes e preparados para enfrentar a vida e o trânsito.",
        },
        {
          img: "./img/FOTO_SCROLL_8.png",
          classTitle: "color-gray",
          title: "Artes no desenvolvimento das crianças",
          text: "A música está ligada ao ser humano desde muito cedo, sem ela o mundo se tornaria vazio e sem espírito. A música é uma arte que vem sendo esquecida, mas que deve ser retomada nas escolas, pois ela propicia ao aluno um aprendizado global e emotivo com o mundo. Na sala de aula, ela poderá auxiliar de forma significativa na aprendizagem.",
        },
      ],
    };
  },
  methods: {
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      } else {
        console.error(`Section with ID '${sectionId}' not found.`);
      }
    },
    handleScroll() {
      const jumpUpButton = document.getElementById("jumpUpButton");
      if (window.scrollY > 100) {
        jumpUpButton.classList.add("visible");
      } else {
        jumpUpButton.classList.remove("visible");
      }
    },
    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title,
        variant,
        appendToast: true,
        autoHideDelay: 5_000,
      });
    },
    isValidEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
      return re.test(String(email).toLowerCase());
    },
    sendForm() {
      if (!this.form.name || this.form.name.lengh <= 0) {
        return this.makeToast("danger", "Aviso", "Informe o campo nome");
      }
      if (!this.form.email || this.form.email.lengh <= 0) {
        return this.makeToast("danger", "Aviso", "Informe o campo e-mail");
      }
      if (!this.isValidEmail(this.form.email)) {
        return this.makeToast("danger", "Aviso", "E-mail inválido");
      }

      if (this.isLoading) return;

      this.isLoading = true;
      const data = {
        nome: this.form.name.toUpperCase(),
        email: this.form.email.toLowerCase(),
      };

      fetch(
        "https://script.google.com/macros/s/AKfycbzUjSPc_KgVA5vrMfri8apsROtelgfV0rG83FYJC1EEKlg4Qkd5We7LoDk5SU0vGK8/exec",
        {
          method: "POST",
          headers: {
            "Content-Type": "text/plain;charset=utf-8",
          },
          body: JSON.stringify(data),
        }
      )
        .then((response) => response.json())
        .then(() =>
          this.makeToast(
            "success",
            "Confirmação",
            "Dado(s) cadastrado(s) com sucesso!"
          )
        )
        .catch(() =>
          this.makeToast(
            "danger",
            "Aviso",
            "Erro ao salvar dado(s). Tente novamente mais tarde"
          )
        )
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style>
.toast:not(.show) {
  display: block;
}
.toast header.toast-header {
  font-family: "Mazzard H Black";
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between !important;
}
.toast .toast-body {
  font-family: "Mazzard";
  font-size: 1rem;
}
.toast button.close {
  background-color: transparent;
  border: none;
}
</style>
<style scoped>
@font-face {
  font-family: "Mazzard";
  src: local("/public/fonts/Mazzard/Mazzard H Regular"),
    local("Mazzard-H-Regular"),
    url("/public/fonts/Mazzard/MazzardH-Regular.woff2") format("woff2"),
    url("/public/fonts/Mazzard/MazzardH-Regular.woff") format("woff"),
    url("/public/fonts/Mazzard/MazzardH-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Mazzard H Black";
  src: local("Mazzard H Black"), local("Mazzard-H-Black"),
    url("/public/fonts/Mazzard/MazzardH-Black.woff2") format("woff2"),
    url("/public/fonts/Mazzard/MazzardH-Black.woff") format("woff"),
    url("/public/fonts/Mazzard/MazzardH-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Mazzard H ExtraLight";
  src: local("Mazzard H ExtraLight"), local("Mazzard-H-ExtraLight"),
    url("/public/fonts/Mazzard/MazzardH-ExtraLight.woff2") format("woff2"),
    url("/public/fonts/Mazzard/MazzardH-ExtraLight.woff") format("woff"),
    url("/public/fonts/Mazzard/MazzardH-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Mazzard H Light";
  src: local("Mazzard H Light"), local("Mazzard-H-Light"),
    url("/public/fonts/Mazzard/MazzardH-Light.woff2") format("woff2"),
    url("/public/fonts/Mazzard/MazzardH-Light.woff") format("woff"),
    url("/public/fonts/Mazzard/MazzardH-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

html,
body,
* {
  margin: 0;
  padding: 0;
}

.bg-cian {
  background-color: #cceaf2;
}
.bg-orange {
  background-color: #f26716;
}
button.bg-orange:hover,
.menu-item.bg-orange:hover {
  background-color: #f1752d;
}
.bg-dark-blue {
  background-color: #193773;
}
.bg-brown {
  background-color: #73200e;
}
button.bg-brown:hover,
.menu-item.bg-brown:hover {
  background-color: #8f331f;
}
.bg-home-left {
  background-image: url("/public/img/BG_HOME.svg");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.bg-gray {
  background-color: #3f6073;
}
button.bg-gray:hover,
.menu-item.bg-gray:hover {
  background-color: #4b758d;
}
.bg-gray-light {
  background-color: #f1f2f2;
}
.color-orange {
  color: #f26716;
}
.color-gray {
  color: #3f6073;
}
.color-dark-cian {
  color: #8eb2be;
}
.color-dark-blue {
  color: #193773;
}
.color-dark-brown {
  color: #73200e;
}
.color-white {
  color: #ffffff;
}

.padding-section {
  padding-top: 24px;
  padding-bottom: 24px;
}

section {
  text-align: left !important;
  overflow: hidden;
}

section h2 {
  margin: 16px 0;
  margin-bottom: 16px;
  font-family: "Mazzard H Black";
  font-size: 2.6rem;
}

section h3 {
  margin: 16px 0;
  margin-bottom: 16px;
  font-family: "Mazzard H Black";
  font-size: 1.8rem;
}

.create-solutions {
  font-size: 3.4rem;
  line-height: 3.4rem;
  text-align: right;
  padding: 12px;
  box-sizing: border-box;
}

.text-solutions {
  font-family: "Mazzard";
  font-size: 1.5rem;
  line-height: normal;
  text-align: left;
}

.text-padding {
  box-sizing: border-box;
  padding: 24px;
}

.cover-padding {
  box-sizing: border-box;
  padding: 0 24px;
}

.cover-products {
  width: fit-content;
}

section p {
  font-family: "Mazzard H Light";
  font-size: 1.2rem;
  text-align: left;
  hyphens: auto;
  -webkit-hyphens: auto;
  word-spacing: -0.05em;
  margin-bottom: 32px;
}

.img-section {
  text-align: right !important;
}

.img-section > img {
  max-height: 50rem;
}

.img-header {
  width: 100%;
}

.logo {
  margin: auto;
  max-height: 150px;
}

.partner {
  width: fit-content;
  max-height: 120px;
  margin: 0 !important;
  text-align: left !important;
  margin-top: 16px !important;
  margin-bottom: 32px !important;
}

.align-center {
  margin: auto;
}

.text-left {
  text-align: left !important;
}

.collection {
  margin-top: 24px;
  margin-bottom: 24px;
}

.collection > img {
  margin-bottom: 24px;
}

.texts-home {
  box-sizing: border-box;
  padding: 24px 36px;
}

.title-home {
  font-family: "Mazzard H Black";
  font-size: 2.5rem !important;
  line-height: 1.9rem;
  margin-top: 32px;
  margin-bottom: 16px;
}

.subtitle-home {
  font-family: "Mazzard H Black";
  font-size: 1.3rem !important;
  line-height: 1.6rem;
}

.menu-item {
  padding: 2rem;
  color: #fff;
  border-bottom-left-radius: 24px;
  border-top-right-radius: 24px;
  color: #ffffff;
  cursor: pointer;
}
#home,
#education,
#mission {
  position: relative;
  overflow: visible;
}
nav {
  position: absolute;
  width: 100%;
  overflow: hidden;
  bottom: -4rem;
  font-family: "Mazzard H Black";
  text-align: center;
  font-size: 1.8rem;
}
.img-float {
  position: absolute;
  bottom: 0;
  right: 0;
  flex: 1;
  max-height: 30rem;
}
.form {
  margin-top: 24px;
}
.form input,
.form button[type="submit"] {
  width: 100%;
  padding: 0.8rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 24px;
  border-top-left-radius: 0;
  border-top-right-radius: 24px;
  border: none;
  color: #ffffff;
  font-family: "Mazzard H Black";
  text-transform: lowercase;
  font-size: 1.4rem;
  margin: 6px 0;
}
.form input::placeholder {
  color: rgba(255, 255, 255, 0.95);
}

.jump-up {
  position: fixed;
  right: 10px;
  bottom: 10px;
  border-radius: 50%;
  border: none;
  width: 40px;
  height: 40px;
  background-color: #3f6073;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.3s;
  visibility: hidden;
}
.jump-up:hover,
jump-up:focus {
  background-color: #527a91;
}
.jump-up::before {
  content: "";
  width: 20px;
  height: 20px;
  background-color: white;
  mask-image: url("/public/img/ARROW_UP.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

.jump-up.visible {
  opacity: 1;
  visibility: visible;
}
footer small {
  font-size: 0.9rem;
  line-height: 0.2rem;
  font-family: "Mazzard H Black";
}

footer a {
  color: #ffffff;
  text-decoration: none;
  display: block;
  font-family: "Mazzard";
  font-size: 1.4rem !important;
  line-height: 1.2rem;
}

footer a.email {
  font-size: 1rem !important;
  line-height: 1rem;
}
.whatsapp-btn {
  width: 3rem;
  height: 3rem;
  background-color: transparent;
  border: none;
  overflow: hidden;
}
.whatsapp-btn:focus {
  background-color: transparent;
  border: none;
}
.whatsapp-btn svg {
  width: 100%;
  height: 100%;
  fill: #ffffff;
}

#childs h2 {
  font-size: 1.6rem;
}
#childs img {
  max-height: 15rem !important;
}
::v-deep .VueCarousel-inner > div {
  box-sizing: border-box;
  padding: 24px;
}
::v-deep .VueCarousel-inner {
  max-height: 40rem;
}

::v-deep .VueCarousel-pagination button:focus {
  border: none;
  outline: none;
}

::v-deep .VueCarousel-dot-container {
  margin-top: 0 !important;
}
@media (max-width: 720px) {
  nav {
    bottom: -1rem !important;
    font-size: 1rem !important;
  }
  .menu-item {
    padding: 0.7rem !important;
    color: #fff;
    border-bottom-left-radius: 24px;
    border-top-right-radius: 24px;
    color: #ffffff;
    cursor: pointer;
  }
  .subtitle-home {
    line-height: 1.4rem;
  }
  .img-float {
    position: relative !important;
    flex-grow: 1 !important;
    float: right;
    margin-bottom: -24px !important;
  }
  #solutions h2 {
    text-align: center !important;
  }
  .text-solutions {
    text-align: left;
  }

  footer a {
    font-size: 1.7rem !important;
    line-height: 1.6rem;
  }
  .whatsapp-btn {
    width: 3.5rem;
    height: 3.5rem;
  }
  ::v-deep .VueCarousel-inner {
    max-height: 42rem !important;
  }
}
@media (max-width: 820px) {
  .partner {
    width: 100% !important;
  }
  .title-home {
    font-size: 1.6rem !important;
    line-height: 1.4rem;
  }
  nav {
    font-size: 1.4rem;
    bottom: -3rem;
  }
  .menu-item {
    padding: 1.2rem;
    border-bottom-left-radius: 24px;
    border-top-right-radius: 24px;
    color: #ffffff;
    cursor: pointer;
  }
  footer a {
    font-size: 1.4rem !important;
    line-height: 1.2rem;
  }
  .whatsapp-btn {
    width: 3.5rem;
    height: 3.5rem;
  }
  ::v-deep .VueCarousel-inner {
    max-height: 60rem !important;
  }
}
</style>

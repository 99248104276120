<template>
  <b-row>
    <b-col cols="12" md="12" class="divider">
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'DividerPage',
}
</script>

<style scoped>
html, body, * {
  margin: 0;
  padding: 0;
}

.divider {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  width: 100% !important;
  background-image: url('/public/img/DIVISOR.png');
  background-size: auto 40px;
  background-repeat: repeat;
  background-position: 50px;
  height: 40px;
}

img {
  max-width: 100% !important;
}
</style>
